// Layers
import {Projection} from "ol/proj";
import {getCenter} from "ol/extent";
import Static from "ol/source/ImageStatic";
import TileLayer from "ol/layer/Tile";
import {XYZ} from "ol/source";
import {TileGrid} from "ol/tilegrid";
import HexGrid from "ol-ext/render/HexGrid";
import {View, Map as OLMap} from "ol";
import HexMap from "ol-games/source/HexMap";
import ImageLayer from "ol/layer/Image";

const extent = [0, 0, 32000, 32000];

const projection = new Projection({
    code: 'pixel-map',
    units: 'pixels',
    extent
});

let cells = new Map();

let layers = [
    new TileLayer({
        source: new XYZ({
          attributions: '',
          tileGrid: new TileGrid({
            extent: extent,
            origin: [0,0],
            resolutions: [128,64,32,16,8,4,2,1],
            tileSize: [256, 256]
          }),
          projection: projection,
            tileUrlFunction: function(tileCoord) {
                return ('http://s3-eu-west-1.amazonaws.com/whereonmars.cartodb.net/celestia_mars-shaded-16k_global/{z}/{x}/{y}.png'
                    .replace('{z}', String(tileCoord[0]))
                    .replace('{x}', String(tileCoord[1]))
                    .replace('{y}', String(-tileCoord[2] - 1)));
            },
        })
    }),
];

// The map
let map = new OLMap({
    target: 'map',
    view: new View({
        projection,
        center: getCenter(extent),
        zoom: 2,
        minZoom: 2,
        maxZoom: 13
    }),
    layers: layers,
});

let grid = new HexGrid({size: 700, origin: map.getView().getCenter()});
grid.setLayout("flat")
let hex = new HexMap({hexGrid: grid});
map.addLayer(new ImageLayer({extent: extent, projection: projection, source: hex, zIndex: 1000}));

map.on(["click"], function (e) { // Coords
    let hex = grid.coord2hex(e.coordinate);
    let k = hex.join(",");
    if (cells.has(k)) {
        map.removeLayer(cells.get(k))
        cells.delete(k)
    } else {
        const c = grid.hex2coord(hex);
        map.addLayer(new ImageLayer({
          source: new Static({
            url: 'https://cdn.meta-force.space/mocks/hexmap/hex_mf_transp.png',
            projection: projection,
            imageExtent: [c[0] - 700, c[1] - 615, c[0] + 700, c[1] + 605],
          }),
        }))
        let layers = map.getLayers().getArray()
        cells.set(k, layers[layers.length - 1])
    }
});